import * as React from 'react';
import { useTPAGallery } from '../../../providers/useTPAGallery/useTPAGallery';
import { TPAGalleryProps } from '../TPAGallery.types';
import styles from './style/TPAGallery.scss';

const TPAGallery: React.FC<TPAGalleryProps> = props => {
  const {
    id,
    url,
    images,
    quality,
    mainPageId,
    compProps,
    compStyles,
    heightOverride,
    isScreenWidth,
    componentReady,
    forwardRef,
    onUnMount = () => {},
    onMouseEnter,
    onMouseLeave,
  } = props;

  function getStyleOverrides() {
    return heightOverride
      ? {
          height: `${heightOverride}px`,
        }
      : {};
  }

  const ref = useTPAGallery(forwardRef, {
    componentReady,
    compProps,
    compStyles,
    quality,
    images,
    mainPageId,
  });

  const fitToScreenWidth = compProps.fitToScreenWidth === false ? false : true;

  React.useEffect(() => {
    return () => {
      onUnMount();
    };
  }, [onUnMount]);

  return (
    <wix-iframe
      id={id}
      data-src={url}
      style={getStyleOverrides()}
      class={isScreenWidth && fitToScreenWidth ? styles.screenWidth : ''}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
      <iframe
        style={getStyleOverrides()}
        className={styles.iframe}
        ref={ref}
        data-src={url}
        scrolling="no"
      />
    </wix-iframe>
  );
};

export default TPAGallery;
